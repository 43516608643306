import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Col, Row, Modal, Button } from 'react-bootstrap';
import bullhornSpeaker from '../../../assets/img/animated-icons/loud-speaker-horn.json';
import Lottie from 'lottie-react';
import { NewRelease as textLang } from 'staticData/languages';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { frontendVersions } from 'staticData/common';
import AppContext from 'context/Context';

const NewRealeseModal = ({ newReleaseModalShow, setNewReleaseModalShow }) => {
  const {
    config: { lang },
    userInfos,
    setUserInfos
  } = useContext(AppContext);

  const handleHideModal = () => {
    let form = new FormData();
    form.append(
      'newFrontendVersion',
      frontendVersions[frontendVersions.length - 1]
    );
    endpointInterface(
      lang,
      'backend',
      'changeFrontedVersion',
      'post',
      true,
      form
    );
    setUserInfos({
      ...userInfos,
      ['frontend_version']: frontendVersions[frontendVersions.length - 1]
    });
    setNewReleaseModalShow(false);
  };

  return (
    <Modal
      show={newReleaseModalShow}
      onHide={() => handleHideModal()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="align-items-center">
          <Col lg={12} className="d-flex justify-content-center">
            <Lottie
              animationData={bullhornSpeaker}
              loop={true}
              style={{ height: '120px', width: '120px' }}
            />
          </Col>
        </Row>
        <h4
          className="text-center font-weight-bold"
          dangerouslySetInnerHTML={{
            __html: textLang.title[lang]
          }}
        ></h4>
        <p className="">
          <p
            className=" m-0"
            dangerouslySetInnerHTML={{
              __html: textLang.text[lang]
            }}
          ></p>
          {/* <br />
          <b
            className="text-primary"
            dangerouslySetInnerHTML={{
              __html: textLang.endText[lang]
            }}
          ></b> */}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => handleHideModal()}
          dangerouslySetInnerHTML={{
            __html: textLang.closing[lang]
          }}
        ></Button>
      </Modal.Footer>
    </Modal>
  );
};

NewRealeseModal.propTypes = {
  newReleaseModalShow: PropTypes.bool.isRequired,
  setNewReleaseModalShow: PropTypes.func.isRequired
};

export default NewRealeseModal;
