import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AuthWizardContext } from 'context/Context';

const AuthWizardProvider = ({ children, step, setStep }) => {
  const [user, setUser] = useState({});
  const value = { user, setUser, step, setStep };
  return (
    <AuthWizardContext.Provider value={value}>
      {children}
    </AuthWizardContext.Provider>
  );
};

AuthWizardProvider.propTypes = {
  children: PropTypes.node.isRequired,
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired
};

export default AuthWizardProvider;
