import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Col, Row, Modal, Button } from 'react-bootstrap';
import checkPrimaryLight from 'assets/img/animated-icons/check-primary-light.json';
import Lottie from 'lottie-react';
import { FirstAccess as textLang } from 'staticData/languages';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { frontendVersions } from 'staticData/common';
import AppContext from 'context/Context';

const FirstAccessModal = ({
  firstAccessModalShow,
  setFirstAccessModalShow
}) => {
  const {
    config: { lang },
    userInfos,
    setUserInfos
  } = useContext(AppContext);

  const handleHideModal = () => {
    let form = new FormData();
    form.append(
      'newFrontendVersion',
      frontendVersions[frontendVersions.length - 1]
    );
    endpointInterface(
      lang,
      'backend',
      'changeFrontedVersion',
      'post',
      true,
      form
    );
    setUserInfos({
      ...userInfos,
      ['frontend_version']: frontendVersions[frontendVersions.length - 1]
    });
    setFirstAccessModalShow(false);
  };

  return (
    <Modal
      show={firstAccessModalShow}
      onHide={() => handleHideModal()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="align-items-center">
          <Col lg={12} className="d-flex justify-content-center">
            <Lottie
              animationData={checkPrimaryLight}
              loop={true}
              style={{ height: '120px', width: '120px' }}
            />
          </Col>
        </Row>
        <h4
          className="text-center font-weight-bold"
          dangerouslySetInnerHTML={{
            __html: textLang.title[lang]
          }}
        ></h4>
        <span
          dangerouslySetInnerHTML={{
            __html: textLang.text[lang]
          }}
        ></span>
        <p className="text-center">
          <b
            className="text-primary"
            dangerouslySetInnerHTML={{
              __html: textLang.endText[lang]
            }}
          ></b>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => handleHideModal()}
          dangerouslySetInnerHTML={{
            __html: textLang.closing[lang]
          }}
        ></Button>
      </Modal.Footer>
    </Modal>
  );
};

FirstAccessModal.propTypes = {
  firstAccessModalShow: PropTypes.bool.isRequired,
  setFirstAccessModalShow: PropTypes.func.isRequired
};

export default FirstAccessModal;
