import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import Flex from 'components/common/Flex';
import { Card, Col, Row } from 'react-bootstrap';
import AppContext from 'context/Context';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GreetingCard as textLang } from 'staticData/languages';
import classNames from 'classnames';
import { breakpoints } from 'helpers/utils';
import { getColor } from 'helpers/utils';
import 'assets/scss/custom/greatingCard.scss';
import { trackEvent } from 'services/externals/mixpanel';

const GreetingCard = ({
  username = '',
  showBody = false,
  generalWallet,
  setAddCapitalModalShow,
  setSupportModalShow,
  onboardCompleted
}) => {
  const {
    config: { lang, currency }
  } = useContext(AppContext);
  const [showOthers, setShowOthers] = useState(false);

  const getGreeting = () => {
    // get hour
    const now = new Date();
    const hour = now.getHours();
    let greeting = '';
    if (hour < 5) {
      greeting = textLang.greatingNight[lang];
    } else if (hour < 12) {
      greeting = textLang.greatingMorning[lang];
    } else if (hour < 17) {
      greeting = textLang.greatingAfternoon[lang];
    } else if (hour < 22) {
      greeting = textLang.greatingEvening[lang];
    } else {
      greeting = textLang.greatingNight[lang];
    }
    return greeting;
  };

  const dashboardButtonMap = {
    0: 'add',
    1: 'referral',
    2: 'support'
  };

  const handleTrackEvent = index => {
    trackEvent('Dashboard button', {
      event: dashboardButtonMap[String(index)]
    });
  };

  const activateModal = () => {
    setAddCapitalModalShow(true);
  };

  const greetingItems = [
    {
      id: 'add',
      icon: 'plus',
      color: 'primary',
      action: () => activateModal()
    },
    {
      id: 'referral',
      icon: 'gift',
      color: 'warning',
      to: '/rewards'
    },
    {
      id: 'feedback',
      icon: 'headset',
      color: 'info',
      action: () => setSupportModalShow(true)
    }
  ];

  const handleActions = (index, action) => {
    handleTrackEvent(index);
    if (action) action();
  };

  return (
    <Card className={'overflow-hidden'}>
      <Card.Header className="position-relative pb-0">
        <div
          className={classNames('position-relative z-index-2 pt-1 d-flex', {
            'pb-3 ps-3': onboardCompleted
          })}
          style={{ justifyContent: 'space-between' }}
          onClick={() => setShowOthers(!showOthers)}
        >
          <div
            className={classNames('align-items-center', {
              'd-flex': onboardCompleted
            })}
          >
            {onboardCompleted && <h4 className="pe-3 mb-0 fs-0">Wallet</h4>}
            <h3
              className={classNames('mb-0', {})}
              dangerouslySetInnerHTML={{
                __html: onboardCompleted
                  ? `${
                      generalWallet != null
                        ? currency + Number(generalWallet).toFixed(2)
                        : ''
                    }`
                  : getGreeting() +
                    (username &&
                      `<font color='${getColor(
                        'primary'
                      )}'> ${username}</font>`)
              }}
            />
          </div>
          <div>
            {onboardCompleted && (
              <FontAwesomeIcon
                icon={`chevron-${showOthers ? 'up' : 'down'}`}
                className="d-sm-none"
              />
            )}
          </div>
        </div>
      </Card.Header>
      {!onboardCompleted && (
        <Card.Body className="py-0">
          <p className="fs--1 text-800">{textLang.pickUpWhereYouLeft[lang]}</p>
        </Card.Body>
      )}
      {showBody && (
        <>
          <hr className="m-0" />
          <Card.Body
            className={classNames('z-index-1', {
              'd-none': window.innerWidth < breakpoints['sm'] && !showOthers
            })}
          >
            <Row className="g-2 h-100 align-items-start">
              {greetingItems.map(
                ({ icon, color, id, to, target, action }, index) => (
                  <Col
                    sm={4}
                    key={id}
                    as={Link}
                    to={{ pathname: to ? to : '' }}
                    target={target || ''}
                    className={classNames('hover-card p-2', {})}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    onClick={() => handleActions(index, action)}
                  >
                    <Flex className="position-relative">
                      <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                        <FontAwesomeIcon
                          icon={icon}
                          className={`text-${color}`}
                        />
                      </div>
                      <div className="flex-1">
                        <h6 className="mb-0">{textLang[id].title[lang]}</h6>
                        <p className="mb-0 fs--2 text-700">
                          {textLang[id].text[lang]}
                        </p>
                      </div>
                    </Flex>
                  </Col>
                )
              )}
            </Row>
            {/* {window.innerWidth < breakpoints['sm'] && (
              <Flex className="justify-content-center">
                <Button
                  variant="link"
                  size="sm"
                  className=""
                  onClick={() => setShowOthers(!showOthers)}
                >
                  {showOthers ? textLang.hide[lang] : textLang.more[lang]}
                  <FontAwesomeIcon
                    icon={`chevron-${showOthers ? 'up' : 'down'}`}
                    className="ms-1 fs--2"
                  />
                </Button>
              </Flex>
            )} */}
          </Card.Body>
        </>
      )}
    </Card>
  );
};

GreetingCard.propTypes = {
  username: PropTypes.string,
  showBody: PropTypes.bool,
  generalWallet: PropTypes.string,
  setAddCapitalModalShow: PropTypes.func,
  setSupportModalShow: PropTypes.func,
  onboardCompleted: PropTypes.bool
};

export default GreetingCard;
