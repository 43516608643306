import React from 'react';
import PropTypes from 'prop-types';
import WizardLayout from './WizardLayout';
import AuthWizardProvider from './AuthWizardProvider';
import 'assets/scss/custom/wizard.scss';

const Wizard = ({
  variant,
  validation,
  progressBar,
  setPage,
  page,
  externalNavItems
}) => {
  return (
    <AuthWizardProvider step={page} setStep={setPage}>
      <WizardLayout
        variant={variant}
        validation={validation}
        progressBar={progressBar}
        externalNavItems={externalNavItems}
      />
    </AuthWizardProvider>
  );
};

Wizard.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  externalNavItems: PropTypes.object
};

export default Wizard;
