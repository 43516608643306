import React, { useContext, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import AppContext from 'context/Context';
import ConfirmMail from 'pages/common/modals/ConfirmMail';
import GreetingCard from '../GreetingCard';
import UserProgress from './UserProgress';
import Tutorials from './Tutorials';
import { useHistory } from 'react-router-dom';

const index = ({
  completionInfo,
  todoList,
  ConfirmMailShow,
  setConfirmMailShow
}) => {
  const [showOthers, setShowOthers] = useState(false);
  const { userInfos } = useContext(AppContext);
  const routerHistory = useHistory();
  const queryParameters = new URLSearchParams(window.location.search);
  const typeUrl = queryParameters.get('type');
  if (!typeUrl) {
    queryParameters.set('type', 'onboard');
    routerHistory.push(`?${queryParameters.toString()}`);
  }

  const getNextAction = info => {
    // It assumes that actionsOrder is available in the scope or passed as a parameter
    for (let key of todoList) {
      if (!info[key.id].value) {
        // Found the first unmet criterion, return its action and description
        return {
          action: info[key.id].action,
          description: key.description,
          id: key.id
        };
      }
    }
    return null;
  };

  return (
    <>
      {userInfos && (
        <ConfirmMail
          ConfirmMailShow={ConfirmMailShow}
          setConfirmMailShow={setConfirmMailShow}
          step={0}
          withHeader={false}
        />
      )}
      <Row className={'mb-3'}>
        <Col xs={12}>
          <GreetingCard
            username={userInfos.username}
            onboardCompleted={false}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} className={'mb-3'}>
          <UserProgress
            info={completionInfo}
            getNextAction={getNextAction}
            todoList={todoList}
            showOthers={showOthers}
            setShowOthers={setShowOthers}
          />
        </Col>
        <Col md={6} className={'mb-3'}>
          <Tutorials />
        </Col>
      </Row>
    </>
  );
};

export default index;
