import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddCapital as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import Flex from 'components/common/Flex';
import { trackEvent } from 'services/externals/mixpanel';

const CoverSection = ({ setType }) => {
  const {
    config: { lang }
  } = useContext(AppContext);

  const altertives = [
    {
      id: 'add',
      icon: 'plus-circle',
      color: 'primary'
    },
    {
      id: 'pac',
      icon: 'wallet',
      color: 'warning'
    }
  ];

  const handleTrackEvent = id => {
    trackEvent('Add capital operation', {
      event: id === 'add' ? 'Add capital' : 'PAC'
    });
  };

  const updateResult = id => {
    setType(id);
    handleTrackEvent(id);
  };

  return (
    <Row className="justify-content-center">
      <Row>
        <h4
          className="mt-2 text-center font-weight-bold"
          dangerouslySetInnerHTML={{
            __html: textLang.firstSectionTitle[lang]
          }}
        ></h4>
      </Row>
      <Row>
        <Col className="pe-0 ps-0 mt-0">
          {altertives.map(({ icon, color, id }) => (
            <Row
              className="m-2 justify-content-center"
              onClick={() => updateResult(id)}
              key={id}
            >
              <Col sm={8}>
                <Card className="hover-border-primary">
                  <Card.Body as={Flex}>
                    <FontAwesomeIcon
                      icon={icon}
                      size="xl"
                      className={`text-${color}`}
                    />
                    <h5 className="mb-0 ms-3">{textLang[id].title[lang]}</h5>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </Row>
  );
};

CoverSection.propTypes = {
  setType: PropTypes.func.isRequired
};

export default CoverSection;
