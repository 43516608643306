import React, { useContext, useState } from 'react';
import FalconCardHeader from 'components/common/FalconCardHeader';
import FalconLink from 'components/common/FalconLink';
import PropTypes from 'prop-types';
import { Button, Card, Form, Row, Col, ProgressBar } from 'react-bootstrap';
import AppContext from 'context/Context';
import { ToDoList as textLang } from 'staticData/languages';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { breakpoints } from 'helpers/utils';

const ToDoListItem = ({ item, completed = false, action }) => {
  return (
    <Flex
      justifyContent="between"
      className={classNames(
        'hover-actions-trigger btn-reveal-trigger px-3 border-200 border-top',
        {
          'hover-bg-100 pointerCursor': action !== null
        }
      )}
      alignItems="center"
      onClick={action !== null ? () => action() : null}
    >
      <Row
        className="justify-content-between align-items-center"
        style={{ width: '-webkit-fill-available' }}
      >
        <Col xs={7}>
          <Form.Check className="py-2 my-2 d-flex align-items-center">
            <Form.Check.Input
              type="checkbox"
              checked={completed}
              className={classNames(
                `mt-0 rounded-circle form-check-input-${item.color}`,
                {
                  'form-check-line-through': completed,
                  'pointerCursor ': action !== null
                }
              )}
            />
            <Form.Check.Label
              className={classNames('flex-1 mb-0 ms-3', {
                'pointerCursor ': action !== null
              })}
            >
              {item.task}
            </Form.Check.Label>
          </Form.Check>
        </Col>
        {item.time && (
          <Col className="px-0 justify-content-end" as={Flex}>
            <span
              className={classNames(`text-nowrap`, {
                'text-decoration-line-through': completed
              })}
            >
              🕒 {item.time}
            </span>
          </Col>
        )}
      </Row>
    </Flex>
  );
};

ToDoListItem.propTypes = {
  item: PropTypes.object.isRequired,
  completed: PropTypes.bool,
  action: PropTypes.func.isRequired
};

const UserProgress = ({ info, todoList, getNextAction }) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const [showOthers, setShowOthers] = useState(false);
  const { action, description } = getNextAction(info);
  const nextId = getNextAction(info).id;

  return (
    <Card className="h-100 bg-line-chart-gradient">
      <FalconCardHeader
        title={textLang.yourProgress[lang]}
        endEl={
          <span onClick={() => action()}>
            <FalconLink title={textLang.continue[lang]} className="px-0" />
          </span>
        }
      />
      <Card.Body>
        <p
          className="text-600"
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
        <ProgressBar
          now={info.level}
          key={1}
          style={{ height: '6px' }}
          className="rounded-pill mb-3"
        />
        <p className="mb-0 text-primary">
          {' '}
          {info.level}% {textLang.completed[lang]}
        </p>
      </Card.Body>

      {(window.innerWidth >= breakpoints['sm'] || showOthers) && (
        <Card.Body className="overflow-hidden">
          {todoList.map(item => (
            <ToDoListItem
              key={item.id}
              completed={info[item.id].value}
              item={item}
              action={item.id === nextId ? action : null}
            />
          ))}
        </Card.Body>
      )}

      {/* ONLY MOBILE */}
      {window.innerWidth < breakpoints['sm'] && !showOthers && (
        <>
          <FalconCardHeader
            title={textLang.next[lang]}
            titleTag="h6"
            className="pb-0"
          />
          <Card.Body className="overflow-hidden">
            {todoList.map(
              item =>
                item.id === nextId && (
                  <ToDoListItem
                    key={item.id}
                    completed={info[item.id].value}
                    item={item}
                    action={item.id === nextId ? action : null}
                  />
                )
            )}
          </Card.Body>
        </>
      )}

      <Card.Footer className="p-0 bg-light">
        {window.innerWidth < breakpoints['sm'] ? (
          <Button
            variant="link"
            size="sm"
            className="w-100 py-2"
            onClick={() => setShowOthers(!showOthers)}
          >
            {showOthers ? textLang.hide[lang] : textLang.more[lang]}
            <FontAwesomeIcon
              icon={`chevron-${showOthers ? 'up' : 'down'}`}
              className="ms-1 fs--2"
            />
          </Button>
        ) : (
          <Button
            onClick={() => action()}
            variant="link"
            size="sm"
            to="#!"
            className="w-100 py-2"
          >
            {textLang.continue[lang]}
            <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2" />
          </Button>
        )}
      </Card.Footer>
    </Card>
  );
};

UserProgress.propTypes = {
  info: PropTypes.object.isRequired,
  todoList: PropTypes.array.isRequired,
  getNextAction: PropTypes.func.isRequired
};

export default UserProgress;
