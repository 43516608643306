import PropTypes from 'prop-types';
import React, { useState, useContext, useEffect } from 'react';
import {
  Col,
  Row,
  Form,
  Card,
  Modal,
  Button,
  FormControl,
  InputGroup,
  ButtonGroup,
  ToggleButton
} from 'react-bootstrap';
import { AddCapital as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import { Link } from 'react-router-dom';
import { currencyMap, coinsData } from 'services/coins/common';
import { calculateCoinsValue } from 'services/coins/functions';
import Lottie from 'lottie-react';
import checkPrimaryLight from 'assets/img/animated-icons/check-primary-light.json';
import Flex from 'components/common/Flex';
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import { cleanCache } from 'services/cache';
import WizardForm from '../wizard/Wizard';
import ButtonGrid from 'pages/CreateBot/ButtonGrid';
import classNames from 'classnames';
import { botGeneralInfo } from 'staticData/common';
import Avatar from 'components/common/Avatar';
import LottieBotLazy from 'services/lazyLoading/LottieBotLazy';

const PacSection = ({ portfolioList, dynamicCoins, setType }) => {
  const {
    config: { lang, currency }
  } = useContext(AppContext);
  const [selectedQuote, setSelectedQuote] = useState('USDC');
  const [selectedBot, setSelectedBot] = useState();
  const [page, setPage] = useState(0);
  const [dayInWeek, setDayInWeek] = useState(0);
  const [dayInMonth, setDayInMonth] = useState(1);
  const [typePac, setTypePac] = useState('MONTHLY');
  const defaultAmount = 200;
  const [amount, setAmount] = useState(defaultAmount);
  const [showError, setShowError] = useState(null);
  const minPacAmount = 50;
  const pacCheck =
    portfolioList.length &&
    portfolioList.some(item => {
      const pac = item?.bot_details?.pac;
      return pac && Object.keys(pac).length > 0;
    });

  const allowedQuotes = ['USDC', 'USDT'];
  const minAmount = 50;
  const stepAmount = 5;
  const maxAmount = 1000;

  const availableDaysInMonth = [...Array(28).keys()];

  const getBotDataById = id => {
    for (const key in botGeneralInfo) {
      if (botGeneralInfo[key].id === id) {
        return { type: key, name: botGeneralInfo[key].name };
      }
    }
    return 'noBot';
  };

  const daysInWeekMap = {
    0: textLang.monday[lang],
    1: textLang.tuesday[lang],
    2: textLang.wednesday[lang],
    3: textLang.thursday[lang],
    4: textLang.friday[lang],
    5: textLang.saturday[lang],
    6: textLang.sunday[lang]
  };

  const availableDaysInWeek = Object.keys(daysInWeekMap);

  const navItems = [
    {
      icon: 'robot',
      label: 'Strategia'
    },
    {
      icon: 'wallet',
      label: 'Wallet'
    },
    {
      icon: 'check',
      label: 'Fatto'
    }
  ];

  const postPacData = async (
    usdtValue,
    scheduleDay = 1,
    scheduleType = 'WEEKLY'
  ) => {
    setShowError(null);
    if (Number(amount) < minPacAmount) {
      setShowError(textLang.minAmountError[lang]);
      return;
    }
    let statusResponse = await endpointInterfaceV2({
      internalEndpoint: 'botPac',
      httpMethod: 'post',
      usingCredentials: true,
      pathParams: {
        bot_id: selectedBot.id
      },
      bodyParams: {
        amounts: {
          [selectedQuote]: Number(usdtValue)
        },
        schedule_day: Number(scheduleDay),
        schedule_type: scheduleType
      }
    });
    if (statusResponse.validResponse) {
      await cleanCache(0);
      setTimeout(() => {
        window.location.reload();
      }, 5000);
      setPage(page + 1);
    }
  };

  const sendData = () => {
    let day = dayInMonth;
    if (typePac == 'WEEKLY') {
      day = dayInWeek;
    }
    postPacData(amount, day, typePac);
  };

  const handleBackPage = () => {
    if (page === 0) setType('');
    else setPage(page - 1);
  };

  const handleNextPage = () => {
    if (page === 0) setPage(page + 1);
    else if (page === 1) sendData();
  };

  const getLastValue = bot => {
    let result = bot.last_stat
      ? Object.keys(dynamicCoins).length
        ? calculateCoinsValue(bot.last_stat.wallet, dynamicCoins)
        : bot.last_stat.eqv[currencyMap[currency]]
      : bot.bot_details.initial_eqv[currencyMap[currency]];
    return result.toFixed(2);
  };

  useEffect(() => {
    if (portfolioList.length && !selectedBot?.id) {
      setSelectedBot(portfolioList[0].bot_details);
    }
  });

  return (
    <>
      <Modal.Body>
        {!pacCheck && (
          <Row className="mt-2 g-3">
            <Col xxl={12} className="mt-0 h-100">
              <WizardForm page={page + 1} externalNavItems={navItems} />
            </Col>
          </Row>
        )}
        {page === 0 && (
          <Row className="justify-content-center">
            <Row>
              {portfolioList.length ? (
                <h4
                  className="mt-2 text-center font-weight-bold"
                  dangerouslySetInnerHTML={{
                    __html: pacCheck
                      ? textLang.noPac[lang]
                      : textLang.secondSectionTitle[lang]
                  }}
                ></h4>
              ) : (
                !pacCheck && (
                  <h5
                    className="mt-4 text-center font-weight-bold"
                    dangerouslySetInnerHTML={{
                      __html: textLang.noBot[lang]
                    }}
                  ></h5>
                )
              )}
            </Row>
            <Row>
              <Col
                xxl={12}
                className="pe-0 ps-0 mt-0 h-100 justify-content-center"
              >
                {portfolioList.length && !pacCheck
                  ? portfolioList.map(bot => (
                      <Row
                        className="mt-3"
                        key={bot.id}
                        onClick={() => setSelectedBot(bot.bot_details)}
                      >
                        <Col>
                          <Card
                            className={classNames('p-2', {
                              'border border-primary':
                                bot.bot_details.id == selectedBot?.id
                            })}
                            style={{
                              backgroundImage: bot.bgStyle,
                              textDecoration: 'none',
                              color: 'inherit'
                            }}
                          >
                            <Form.Check
                              type="radio"
                              id={`address-${bot.bot_details.id}`}
                              className="form-check radio-select"
                            >
                              <Form.Check.Input
                                type="radio"
                                name="walletType"
                                id={bot.bot_details.id}
                                checked={bot.bot_details.id == selectedBot?.id}
                                value={bot.bot_details.id}
                              />

                              <Form.Check.Label>
                                <Card.Body className="py-0">
                                  <Row>
                                    <Row className="align-items-center">
                                      <Row>
                                        <Col
                                          sm={4}
                                          className="justify-content-center"
                                        >
                                          <LottieBotLazy
                                            type={
                                              getBotDataById(
                                                bot.bot_details.type
                                              ).type
                                            }
                                            loop={true}
                                            style={{ width: '100%' }}
                                          />
                                        </Col>
                                        <Col
                                          sm={5}
                                          className="align-items-center"
                                          as={Flex}
                                        >
                                          <div>
                                            <small>AI Portfolio</small>
                                            <h4>{bot.name}</h4>
                                            {bot.riskLevel && (
                                              <Flex className="align-items-center">
                                                <h6 className="mb-0 me-2">
                                                  {textLang.riskLevel[lang]}
                                                </h6>
                                                <ButtonGrid
                                                  actives={[bot.riskLevel]}
                                                  click={true}
                                                  buttonSize={'sm'}
                                                />
                                              </Flex>
                                            )}
                                          </div>
                                        </Col>
                                        <Col
                                          md={3}
                                          className="align-items-center"
                                          as={Flex}
                                        >
                                          <div>
                                            <p className="mt-2 mb-0 fs--1">
                                              {textLang.managed[lang]}
                                            </p>
                                            <p className="mt-2">
                                              <b className={'text-dark'}>
                                                {currency}
                                                {getLastValue(bot)}
                                              </b>
                                            </p>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Row>
                                  </Row>
                                </Card.Body>
                              </Form.Check.Label>
                            </Form.Check>
                          </Card>
                        </Col>
                      </Row>
                    ))
                  : !pacCheck && (
                      <Row className="mt-2 mb-4 align-items-center justify-content-center">
                        <Col
                          className="text-center font-weight-bold align-items-center justify-content-center"
                          as={Link}
                          to="/createbot?type=bot"
                        >
                          {textLang.goToStart[lang]}
                        </Col>
                      </Row>
                    )}
              </Col>
            </Row>
          </Row>
        )}
        {page === 1 && (
          <>
            <h4
              className="mt-2 text-center font-weight-bold"
              dangerouslySetInnerHTML={{
                __html: textLang.thirdSectionTitle[lang]
              }}
            ></h4>
            <Row className="justify-content-center">
              <Col
                xs={6}
                className="align-items-center mt-4 mb-3 gutter-x-0"
                style={{ textAlign: 'center' }}
              >
                <Row className="pb-2 d-flex align-items-center">
                  <Col className="justify-content-center d-flex">
                    <ButtonGroup>
                      {['WEEKLY', 'MONTHLY'].map(item => (
                        <ToggleButton
                          id={`radio-` + item}
                          key={item}
                          type="radio"
                          variant={'outline-primary'}
                          name="radio"
                          value={item}
                          checked={typePac === item}
                          onChange={e => setTypePac(e.currentTarget.value)}
                        >
                          {textLang[item.toLowerCase()][lang]}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </Col>
                </Row>
                <Row className="pt-2 pb-2 d-flex align-items-center justify-content-center">
                  <Col sm={8}>
                    {typePac == 'WEEKLY' && (
                      <InputGroup size="sm">
                        <Form.Select
                          className="pe-5 "
                          defaultValue={dayInWeek}
                          onChange={({ target }) => setDayInWeek(target.value)}
                        >
                          {availableDaysInWeek.map(item => (
                            <option key={item} value={item}>
                              {daysInWeekMap[item]}
                            </option>
                          ))}
                        </Form.Select>
                      </InputGroup>
                    )}
                    {typePac == 'MONTHLY' && (
                      <InputGroup size="sm">
                        <Form.Select
                          className="pe-5 "
                          defaultValue={dayInMonth}
                          onChange={({ target }) => setDayInMonth(target.value)}
                        >
                          {availableDaysInMonth.map(item => (
                            <option key={item} value={item + 1}>
                              {item + 1}° giorno del mese
                            </option>
                          ))}
                        </Form.Select>
                      </InputGroup>
                    )}
                  </Col>
                </Row>
                <hr />
                <Row className="pt-2 pb-2 d-flex align-items-center justify-content-center">
                  <Col className="justify-content-center d-flex">
                    <ButtonGroup>
                      {allowedQuotes.map(item => (
                        <ToggleButton
                          id={`quote-` + item}
                          key={item}
                          type="radio"
                          variant={'outline-primary'}
                          name="quote"
                          value={item}
                          checked={selectedQuote === item}
                          onChange={e =>
                            setSelectedQuote(e.currentTarget.value)
                          }
                        >
                          {item}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </Col>
                </Row>

                <Row className="pb-2 d-flex align-items-center justify-content-center">
                  <h6 className="mb-1 text-secondary position-relative">
                    {textLang.amount[lang] + ' (' + selectedQuote + ')'}
                  </h6>
                  <Col
                    xs={4}
                    sm={2}
                    className="d-flex align-items-center justify-content-center flex-row"
                  >
                    <Avatar src={coinsData[selectedQuote].img}></Avatar>
                  </Col>
                  <Col xs={8} className="ms-0 ps-0">
                    <InputGroup className="ms-0 p-1">
                      <FormControl
                        type="number"
                        onChange={e => setAmount(e.target.value)}
                        min={minAmount}
                        step={stepAmount}
                        max={maxAmount}
                        value={amount}
                      />
                    </InputGroup>
                  </Col>
                  {showError && (
                    <p className="text-danger">
                      <b>{showError}</b>
                    </p>
                  )}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <small>{textLang.pacDisclaimer[lang]}</small>
              </Col>
            </Row>
          </>
        )}
        {page === 2 && (
          <>
            <Row
              className="align-items-center mt-6 mb-6 gutter-x-0"
              style={{ textAlign: 'center' }}
            >
              <Col>
                <Flex className="justify-content-center">
                  <Lottie
                    animationData={checkPrimaryLight}
                    style={{ height: '120px', width: '120px' }}
                  />
                </Flex>
                <h2 className="fw-normal text-900 mt-2">
                  {textLang.yourPersonal[lang]}
                  {textLang.success[lang]}
                </h2>
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
      {page < 2 && (
        <Modal.Footer>
          <Col className="justify-content-start">
            <Button
              variant="secondary"
              onClick={() => handleBackPage()}
              dangerouslySetInnerHTML={{
                __html: textLang.back[lang]
              }}
            />
          </Col>
          {!pacCheck && (
            <Col as={Flex} className="justify-content-end">
              <Button
                variant="primary"
                onClick={() => handleNextPage()}
                dangerouslySetInnerHTML={{
                  __html: textLang.confirm[lang]
                }}
              />
            </Col>
          )}
        </Modal.Footer>
      )}
    </>
  );
};

PacSection.propTypes = {
  portfolioList: PropTypes.array,
  dynamicCoins: PropTypes.object,
  pacCheck: PropTypes.object,
  setType: PropTypes.func
};

export default PacSection;
