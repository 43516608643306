import React /*useContext , useState*/ from 'react';
import { Card /*, Button*/ } from 'react-bootstrap';
// import FalconCardHeader from 'components/common/FalconCardHeader';
import ReactYoutubePlayer from 'react-player/youtube';
// import ReactVimeoPlayer from 'react-player/vimeo';
// import AppContext from 'context/Context';
// import { Tutorials as textLang } from 'staticData/languages';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import classNames from 'classnames';
import { breakpoints } from 'helpers/utils';
import { trackEvent } from 'services/externals/mixpanel';

const Tutorials = () => {
  // const {
  //   config: { lang }
  // } = useContext(AppContext);
  // const [showOthers, setShowOthers] = useState(false);
  const handleTrackEvent = () => {
    trackEvent('Video Click', {});
  };

  return (
    <Card className="h-100">
      {/* <FalconCardHeader title={textLang.title[[lang]]} /> */}
      <Card.Body onClick={() => handleTrackEvent()}>
        <div className="mb-3">
          <ReactYoutubePlayer
            url="https://youtu.be/HdH1Pq68jkU"
            controls={true}
            className="react-player"
            height={window.innerWidth < breakpoints['sm'] ? '200px' : '500px'}
          />
          {/* <ReactVimeoPlayer
            url="https://vimeo.com/963373998"
            controls={true}
            className="react-player"
            height={window.innerWidth < breakpoints['sm'] ? '200px' : '500px'}
          /> */}
        </div>
        {/* <>
          <div
            className={classNames('mb-3', {
              'd-none': window.innerWidth < breakpoints['sm'] && !showOthers
            })}
          >
            <ReactYoutubePlayer
              url="https://www.youtube.com/watch?v=Qz-Rfh_FXws"
              controls={true}
              className="react-player"
              height={'150px'}
            />
          </div>
          <div
            className={classNames('mb-3', {
              'd-none': window.innerWidth < breakpoints['sm'] && !showOthers
            })}
          >
            <ReactYoutubePlayer
              url="https://www.youtube.com/watch?v=QaERtsPMXSU"
              controls={true}
              className="react-player"
              height={'150px'}
            />
          </div>
        </> */}
      </Card.Body>
      {/* {window.innerWidth < breakpoints['sm'] && (
        <Button
          variant="link"
          size="sm"
          className="ps-0 mb-3"
          onClick={() => setShowOthers(!showOthers)}
        >
          {showOthers ? textLang.hide[lang] : textLang.more[lang]}
          <FontAwesomeIcon
            icon={`chevron-${showOthers ? 'up' : 'down'}`}
            className="ms-1 fs--2"
          />
        </Button>
      )} */}
    </Card>
  );
};

export default Tutorials;
