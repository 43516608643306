import React, { useState, useContext, useEffect } from 'react';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import AppContext from 'context/Context';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { newsImgMap } from 'staticData/newsData';
import IconItem from 'components/common/icon/IconItem';
import { Sentiment as textLang, monthsList } from 'staticData/languages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const Project = ({ project, isLast }) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const { id, ts, title, source_name, duplicates } = project;
  const translatedTitle = typeof title !== 'object' ? title : title[lang];
  const date = new Date(ts);
  let months = monthsList.months[lang];
  const showDate =
    date.getDate() +
    ' ' +
    months[date.getMonth()] +
    ' ' +
    date.getHours() +
    ':' +
    (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());

  const sourceHasImg = Object.keys(newsImgMap).includes(
    source_name.replace(/\s+/g, '').toLowerCase()
  );

  const fireEmoji = '\u{1F525}';
  const isHot = Number(duplicates) >= 5;

  return (
    <Row
      as={Link}
      to={`/sentiment?id=${id}`}
      style={{ textDecoration: 'none', color: 'inherit' }}
      className={classNames(
        'position-relative align-items-center py-2 hover-bg-200',
        {
          'border-bottom border-200 ': !isLast
        }
      )}
    >
      <Col xs={12} sm={9} className="py-1">
        <Flex className="align-items-center">
          <div className=" me-3" style={{ position: 'relative' }}>
            {sourceHasImg ? (
              <img
                src={newsImgMap[source_name.replace(/\s+/g, '').toLowerCase()]}
                alt={source_name}
                width="30"
                height="30"
                className={`rounded-circle p-1`}
              />
            ) : (
              <IconItem
                tag="div"
                icon={'newspaper'}
                size="sm"
                iconClass="fs--2"
                className="shadow-none"
              />
            )}
            {isHot && (
              <span style={{ position: 'absolute', bottom: '-5px', right: 0 }}>
                {fireEmoji}
              </span>
            )}
          </div>
          <h6 className="mb-0">
            {translatedTitle}
            <div>
              <small className="text-800 d-sm-none">
                <span className="me-2">{showDate}</span>
              </small>
              <small className="text-800 d-none d-sm-block">
                {source_name}
              </small>
            </div>
          </h6>
        </Flex>
      </Col>
      <Col sm={3} className="d-none d-sm-block">
        <Row className="justify-content-center align-items-center">
          <Col xs={8} sm={8} className="px-0 d-flex justify-content-center">
            <div className="fs--1 fw-semi-bold">{showDate}</div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

Project.propTypes = {
  project: PropTypes.object,
  isLast: PropTypes.bool
};

const NewsList = () => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const [sentimentInfo, setSentimentInfo] = useState(null);

  const getSentimentInfo = async () => {
    const daysAgo = 1;
    const newsNumber = 3;
    var params = {
      news: JSON.stringify([{ days: daysAgo, count: newsNumber }])
    };
    let sentimentResp = await endpointInterface(
      lang,
      'backend',
      'getSentiment',
      'get',
      true,
      params,
      true
    );
    let localSentimentInfo = [];
    if (sentimentResp.validResponse) {
      localSentimentInfo = sentimentResp.data.news[daysAgo];
    } else {
      toast.error(sentimentResp.responseMessage, {
        closeButton: false
      });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
    }
    setSentimentInfo(localSentimentInfo);
  };

  useEffect(() => {
    getSentimentInfo();
  }, []);

  return (
    <Card>
      <Card.Header
        as={Link}
        to="/sentiment"
        style={{ textDecoration: 'none', color: 'inherit' }}
      >
        <h4 className="mb-0">
          {textLang.readLatestNews[lang]}
          <FontAwesomeIcon icon="chevron-right" className="ms-2 fs-1" />
        </h4>
      </Card.Header>
      <Card.Body className="py-0">
        {!sentimentInfo ? (
          <Flex style={{ padding: '5% 5%' }} className="justify-content-center">
            <Spinner />
          </Flex>
        ) : sentimentInfo.length ? (
          sentimentInfo.map((project, index) => (
            <Project
              key={index}
              project={project}
              isLast={index === sentimentInfo.length - 1}
            />
          ))
        ) : (
          <Flex style={{ padding: '5% 5%' }} className="justify-content-center">
            {textLang.noData[lang]}
          </Flex>
        )}
      </Card.Body>
    </Card>
  );
};

export default NewsList;
