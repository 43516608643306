import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';

const DeleteAccount = () => {
  const sendData = async () => {
    let sendDataResponse = await endpointInterface(
      'en-EN',
      'backend',
      'backendVer',
      'get',
      true
    );
    if (sendDataResponse.validResponse) {
      console.info('Data sent');
    }
  };

  return (
    <>
      <h3>Insert mail and reason to delete your acocunt</h3>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="name@example.com" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>add some feedback</Form.Label>
        <Form.Control as="textarea" rows={3} />
      </Form.Group>
      <Button variant="primary" onClick={sendData}>
        Submit
      </Button>
    </>
  );
};

export default DeleteAccount;
