import PropTypes from 'prop-types';
import React, { useState, useContext, useEffect } from 'react';
import { Col, Row, Form, Card, Modal, Button } from 'react-bootstrap';
import { AddCapital as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import SoftBadge from 'components/common/SoftBadge';
import { Link } from 'react-router-dom';
import { currencyMap } from 'services/coins/common';
import { calculateCoinsValue } from 'services/coins/functions';
import Flex from 'components/common/Flex';
import { botGeneralInfo } from 'staticData/common';
import { walletImgRender } from 'services/coins/components';
import AddLiquidityModal from 'pages/common/modals/AddLiquidity';
import classNames from 'classnames';
import LottieBotLazy from 'services/lazyLoading/LottieBotLazy';

const AddCapitalSelection = ({ botList, dynamicCoins, setType }) => {
  const {
    config: { lang, currency }
  } = useContext(AppContext);

  const [selectedBot, setSelectedBot] = useState();
  const [addLiquidityModalShow, setAddLiquidityModalShow] = useState(false);
  const [showDataInModal, setShowDataInModal] = useState(true);

  const getLastValue = bot => {
    let result = bot.last_stat
      ? Object.keys(dynamicCoins).length
        ? calculateCoinsValue(bot.last_stat.wallet, dynamicCoins)
        : bot.last_stat.eqv[currencyMap[currency]]
      : bot.bot_details.initial_eqv[currencyMap[currency]];
    return result.toFixed(2);
  };

  const getBotDataById = id => {
    for (const key in botGeneralInfo) {
      if (botGeneralInfo[key].id === id) {
        return { type: key, name: botGeneralInfo[key].name };
      }
    }
    return 'noBot';
  };

  const botStatusColor = status => {
    if (status.toUpperCase() === 'ACTIVE') return 'success';
    if (status.toUpperCase() === 'ERROR') return 'danger';
    else return 'warning';
  };

  const handleContinueModal = () => {
    let bot = botList.find(item => item.bot_details.id == selectedBot?.id);
    if (bot) handleChangeModalLiquidityStatus(true);
  };

  const handleChangeModalLiquidityStatus = newState => {
    setAddLiquidityModalShow(newState);
    setShowDataInModal(!newState);
  };

  const handleAddedLiquidity = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (botList.length && !selectedBot?.id)
      setSelectedBot(botList[0].bot_details);
  }, []);

  return (
    <>
      {selectedBot?.id && (
        <AddLiquidityModal
          modalShow={addLiquidityModalShow}
          setModalShow={handleChangeModalLiquidityStatus}
          setAddedLiquidity={handleAddedLiquidity}
          botID={selectedBot?.id}
          type={selectedBot?.category}
          multiBot
        />
      )}
      {showDataInModal && (
        <>
          <Modal.Body>
            <Row className="justify-content-center">
              <Row>
                {botList.length ? (
                  <h4
                    className="mt-2 text-center font-weight-bold"
                    dangerouslySetInnerHTML={{
                      __html: textLang.secondSectionTitle[lang]
                    }}
                  ></h4>
                ) : (
                  <h5
                    className="mt-4 text-center font-weight-bold"
                    dangerouslySetInnerHTML={{
                      __html: textLang.noBot[lang]
                    }}
                  ></h5>
                )}
              </Row>
              <Row>
                <Col
                  xxl={12}
                  className="pe-0 ps-0 mt-0 h-100 justify-content-center"
                >
                  {botList.length ? (
                    botList.map(
                      bot =>
                        bot.bot_details.status !== 'ERROR' && (
                          <>
                            <Row
                              className="mt-3"
                              key={bot.id}
                              onClick={() => setSelectedBot(bot.bot_details)}
                            >
                              <Col>
                                <Card
                                  className={classNames('p-2 pointerCursor', {
                                    'border border-primary':
                                      bot.bot_details.id == selectedBot?.id
                                  })}
                                  style={
                                    bot.bot_details?.category === 'portfolio'
                                      ? {
                                          backgroundImage: bot.bgStyle,
                                          textDecoration: 'none',
                                          color: 'inherit'
                                        }
                                      : {}
                                  }
                                >
                                  <Form.Check
                                    type="radio"
                                    id={`address-${bot.bot_details.id}`}
                                    className="form-check radio-select"
                                  >
                                    <Form.Check.Input
                                      type="radio"
                                      name="walletType"
                                      id={bot.bot_details.id}
                                      checked={
                                        bot.bot_details.id == selectedBot?.id
                                      }
                                      value={bot.bot_details.id}
                                    />

                                    <Form.Check.Label>
                                      {/* > MOBILE */}
                                      <Card.Body className="py-0 d-none d-sm-block">
                                        <Row>
                                          <Row className="align-items-center">
                                            <Col md={3}>
                                              <LottieBotLazy
                                                type={
                                                  getBotDataById(
                                                    bot.bot_details.type
                                                  ).type
                                                }
                                                loop={true}
                                                style={{ width: '80%' }}
                                              />
                                            </Col>
                                            <Col md={3}>
                                              <h5 className="mb-0">
                                                {bot.bot_details?.category ===
                                                'portfolio'
                                                  ? bot.name
                                                  : bot.bot_details.name}
                                              </h5>
                                              <small>
                                                {getBotDataById(
                                                  bot.bot_details.type
                                                ).name || ''}
                                              </small>
                                            </Col>
                                            <Col md={3}>
                                              <h5 className="text-center">
                                                Asset
                                              </h5>
                                              {walletImgRender(bot.coins)}
                                            </Col>
                                            <Col md={3} className="text-center">
                                              <p className="mt-2 mb-0 fs--1">
                                                {textLang.managed[lang]}
                                              </p>
                                              <p className="mt-2">
                                                <b className={'text-dark'}>
                                                  {currency}
                                                  {getLastValue(bot)}
                                                </b>
                                              </p>
                                            </Col>
                                          </Row>
                                        </Row>
                                      </Card.Body>

                                      {/* MOBILE */}
                                      <Card.Body className="py-0 d-sm-none">
                                        <Row className="align-items-center pt-3">
                                          <Col xs={6}>
                                            <LottieBotLazy
                                              type={
                                                getBotDataById(
                                                  bot.bot_details.type
                                                ).type
                                              }
                                              loop={true}
                                              style={{ width: '80%' }}
                                            />
                                          </Col>
                                          <Col xs={6} className="ps-4">
                                            <Row className="pb-2">
                                              <Col>
                                                {walletImgRender(bot.coins, '')}
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col>
                                                <h6 className="mb-0 fs-1">
                                                  {bot.bot_details?.category ===
                                                  'portfolio'
                                                    ? bot.name
                                                    : bot.bot_details.name}
                                                </h6>
                                                <small>
                                                  {getBotDataById(
                                                    bot.bot_details.type
                                                  ).name || ''}
                                                </small>
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                        <Row className="py-3">
                                          <Col>
                                            <Row>
                                              <Col>
                                                <p className="mb-0 fs--1">
                                                  {textLang.managed[lang]}
                                                </p>
                                              </Col>
                                              <Col>
                                                <p className="mb-0 ms-3">
                                                  <b className={'text-dark'}>
                                                    {currency}
                                                    {getLastValue(bot)}
                                                  </b>
                                                </p>
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                      {/* BOT STATUS: Top Left */}
                                      <div
                                        style={{
                                          position: 'absolute',
                                          top: '10px',
                                          left: '40px'
                                        }}
                                      >
                                        <SoftBadge
                                          pill
                                          bg={botStatusColor(
                                            bot.bot_details.status
                                          )}
                                        >
                                          {bot.bot_details.status.toUpperCase() ===
                                          'ACTIVE'
                                            ? textLang.active[lang]
                                            : bot.bot_details.status.toUpperCase() ===
                                              'PAUSED'
                                            ? textLang.paused[lang]
                                            : textLang.error[lang]}
                                        </SoftBadge>
                                      </div>
                                    </Form.Check.Label>
                                  </Form.Check>
                                </Card>
                              </Col>
                            </Row>
                            {bot.bot_details?.category === 'portfolio' &&
                              botList.length > 1 && <hr />}
                          </>
                        )
                    )
                  ) : (
                    <></>
                  )}

                  {!botList.length ? (
                    <Row className="mt-2 mb-4 align-items-center justify-content-center">
                      <Col
                        className="text-center font-weight-bold align-items-center justify-content-center"
                        as={Link}
                        to="/createbot?type=bot"
                      >
                        {textLang.goToStart[lang]}
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col className="justify-content-start">
              <Button
                variant="secondary"
                onClick={() => setType()}
                dangerouslySetInnerHTML={{
                  __html: textLang.back[lang]
                }}
              />
            </Col>
            <Col as={Flex} className="justify-content-end">
              <Button
                variant="primary"
                onClick={() => handleContinueModal()}
                dangerouslySetInnerHTML={{
                  __html: textLang.continue[lang]
                }}
              ></Button>
            </Col>
          </Modal.Footer>
        </>
      )}
    </>
  );
};

AddCapitalSelection.propTypes = {
  botList: PropTypes.array,
  dynamicCoins: PropTypes.object,
  setType: PropTypes.func
};

export default AddCapitalSelection;
